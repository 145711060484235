import { Directory, Filesystem } from "@capacitor/filesystem";
import AppDatabase from "./db/app-db.service";
import { projectFilePath } from "./db/filesystem.service";
import { Capacitor } from "@capacitor/core";
import { store } from "@stencil/redux";
import { getAppId } from "../store/selectors";

const getMapIconUrl = async (projectId: number, itemId: number) => {
  const path = projectFilePath(projectId, 'items', itemId);
  const result = await Filesystem.getUri({
    directory: Directory.Data,
    path
  });
  return Capacitor.convertFileSrc(result.uri);
}

export class MapIconService {

  private currentProjectId: number;
  private icons: Set<string> = new Set();

  private constructor() { }

  init() {
    AppDatabase.instance.events.addListener('syncDidUpdate', (_projectId: number, _records: boolean, config: boolean) => {
      if (config) {
        this.clearIcons();
      }
    });
    store.getStore().subscribe(this.run);
    this.run();
  }

  private run = () => {
    const projectId = getAppId(store.getState());
    if (projectId !== this.currentProjectId) {
      this.currentProjectId = projectId;
      this.clearIcons();
    }
  }

  public static instance: MapIconService = new MapIconService();
  private map: mapboxgl.Map;

  public setMap(map: mapboxgl.Map) {
    this.map = map;

    map.on('styleimagemissing', async (e) => {
      const [projectId, itemId] = e.id.split('_').map(n => parseInt(n, 10));
      let url: string;
      if (Capacitor.isNativePlatform()) {
        url = await getMapIconUrl(projectId, itemId);
      } else {
        const item = await AppDatabase.instance.items.findById(itemId);
        url = item.icon;
      }
      const key = `${projectId}_${itemId}`;
      this.icons.add(key);
      this.applyIcon(key, url);
      console.log('styleimagemissing', e);
    });
  }

  private async applyIcon(key: string, url: string) {
    return new Promise<void>((resolve, reject) => {
      this.map.loadImage(url, (error, image) => {
        if (error) {
          reject(error);
        } else if (!this.map.hasImage(key)) {
          this.map.addImage(key, image);
        } else {
          this.map.removeImage(key);
          this.map.addImage(key, image);
        }
        resolve();
      });
    });
  }

  private clearIcons() {
    for (const key of this.icons) {
      this.map.removeImage(key);
    }
    this.icons.clear();
  }

}